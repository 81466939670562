import React from "react"
import { Flex } from "@chakra-ui/core"

function MasonryColumns({ column, columns }) {
  return (
    <Flex
      key={columns}
      className="masonry-column"
      direction="column"
      w={`${100 / columns}%`}
      maxWidth={`${100 / columns}%`}
      // pt={paddingTop[index]}
      pt={0}
    >
      {column.map(item => item)}
    </Flex>
  )
}

export default MasonryColumns
