import React from "react"
import Img from "gatsby-image"
import { Box, Icon } from "@chakra-ui/core"
import { css } from "@emotion/core"

const styling = css`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 100;
  pointer-events: none;
`

const ImageWithOverlaycolor = ({
  image,
  imageGray,
  aspectRatio,
  onlyOverlayAndChild = false,
  children,
  frame,
  alt,
  icon,
}) => {
  const handlePreventClick = e => {
    return false
  }

  return (
    <Box
      w={"100%"}
      h={aspectRatio <= 0.5 ? ["50em", "30em", "24em"] : "100%"}
      pos={image ? "relative" : ""}
      border={frame && ["4px solid #db5b51"]}
      pointerEvents="none"
      onMouseDown={handlePreventClick}
      onClick={handlePreventClick}
      onContextMenu={handlePreventClick}
    >
      <Box css={styling}></Box>
      {imageGray && (
        <Img
          alt={alt ? alt : ""}
          className="image-gray"
          fluid={imageGray}
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 4,
          }}
          imgStyle={{
            objectFit: "cover",
            margin: 0,
            objectPosition: "top center",
          }}
        />
      )}
      {onlyOverlayAndChild && children}
      {image && (
        <Img
          className="image-color"
          fluid={image}
          style={{
            height: "100%",
            width: "100%",
            position: "relative",
            top: 0,
            left: 0,
            visibility: "visible",
            zIndex: 3,
          }}
          imgStyle={{
            objectFit: "cover",
            margin: 0,
            objectPosition: "top center",
          }}
        />
      )}
      {icon && (
        <Icon
          name={icon}
          color="red"
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex="4"
          w={["9.2em", "5.1em", "4.1em"]}
          /* h={["5em", "3em", "1.8vw"]} */
          h="auto"
        />
      )}
    </Box>
  )
}

export default ImageWithOverlaycolor
