import { useEffect, useState, useCallback } from "react"

const useMedia = (
  queries = ["(min-width: 48em)", "(min-width: 75em)"],
  values = [2, 3],
  defaultValue = 2
) => {
  // Array containing a media query list for each query
  const mediaQueryLists = queries
    .reverse()
    .map(q => typeof window !== "undefined" && window.matchMedia(q))

  // Function that gets value based on matching media query
  const getValue = useCallback(() => {
    // Get index of first media query that matches
    const index =
      values.length - 1 - mediaQueryLists.findIndex(mql => mql.matches)
    // Return related value or defaultValue if none
    return typeof values[index] !== "undefined" ? values[index] : defaultValue
  }, [mediaQueryLists, defaultValue, values])

  // State and setter for matched value
  const [value, setValue] = useState(getValue)

  useEffect(
    () => {
      // Event listener callback
      // Note: By defining getValue outside of useEffect we ensure that it has ...
      // ... current values of hook args (as this hook callback is created once on mount).
      const handler = () => setValue(getValue)
      // Set a listener for each media query with above handler as callback.
      mediaQueryLists.forEach(mql => mql.addListener(handler))
      // Remove listeners on cleanup
      return () => mediaQueryLists.forEach(mql => mql.removeListener(handler))
    },
    [mediaQueryLists, getValue] // Empty array ensures effect is only run on mount and unmount
  )

  return value
}

export default useMedia
