import React, { useState } from "react"
import { graphql } from "gatsby"

import { BuchhandlungTempl } from "../pageTemplates/buchhandlung"

const Buchhandlung = ({ data, location }) => (
  <BuchhandlungTempl data={data} location={location} />
)

export const query = graphql`
  query {
    strapi {
      buchhandlung {
        meta {
          title
          description
        }
        inhalt {
          __typename
          ... on STRAPI_ComponentPageArticle {
            id
            article {
              text
              title
              subtitle
              use_alternative_font
              cover {
                __typename
                url
                caption
                alternativeText
                imageFileGray: imageFile {
                  childImageSharp {
                    fluid(
                      duotone: { highlight: "#ffffff", shadow: "#007c60" }
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
              gallery {
                url
                caption
                alternativeText
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageInformation {
            id
            text
            title
            __typename
            image {
              url
              caption
              alternativeText
              imageFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageText {
            id
            text
            __typename
          }
        }
        inhalt_en {
          __typename
          ... on STRAPI_ComponentPageInformation {
            id
            text
            title
            __typename
            image {
              url
              caption
              alternativeText
              imageFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageText {
            id
            text
            __typename
          }
          ... on STRAPI_ComponentPageArticle {
            id
            article {
              text
              title
              subtitle
              use_alternative_font
              cover {
                __typename
                url
                caption
                alternativeText
                imageFileGray: imageFile {
                  childImageSharp {
                    fluid(
                      duotone: { highlight: "#ffffff", shadow: "#007c60" }
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
              gallery {
                url
                caption
                alternativeText
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Buchhandlung
