import React from "react"
import styles from "./event.module.css"
import article_styles from "./article.module.css"
import { Flex, Box } from "@chakra-ui/core"
import { css } from "@emotion/core"

import ReactMarkdown from "react-markdown"
import RemarkBreaks from "remark-breaks"

import ImageWithOverlaycolor from "components/imageWithOverlaycolorOrChild"
import theme from "root/theme"

const Article = ({
  title,
  subtitle,
  text,
  image,
  imageGray,
  justInformation = false,
  gallery,
  textColor = "black",
  fontFamily = "Traulha Jornau",
  useAlternativeFont = false,
  showLightboxHint = false,
  setData,
  viewLightbox,
  copyright,
  alt,
}) => {
  const imageAspectRatio =
    image && image.childImageSharp && image.childImageSharp.fluid && image.childImageSharp.fluid.aspectRatio

  const passData = () => {
    setData({ title, text, gallery, useAlternativeFont })
    viewLightbox(true)
  }

  return (
    <Flex
      as="article"
      direction="column"
      pr={["8em", "5em", "5em"]}
      onClick={() => !justInformation && setData && passData()}
      cursor={justInformation ? "" : "pointer"}
      css={css`
        .image-gray {
          visibility: visible;
        }
        @media (hover) {
          &:hover .title {
            color: ${justInformation || image ? "" : theme.colors.brown};
          }
          &:hover .image-gray {
            visibility: hidden;
          }
        }
      `}
    >
      {title && (
        <Box
          className="title"
          as="h6"
          color="red"
          pt={["3vw", "1.5vw", "1vw"]}
          fontSize={["6xl", "4xl", "3xl"]}
          lineHeight="1.25em"
        >
          {title}
        </Box>
      )}
      {subtitle && (
        <Box
          lineHeight="1.25em"
          className="subtitle"
          as="h6"
          color="green"
          pt={["0", "0vw", "0vw"]}
          pb={["0.2em", "0.1em", "0.2em"]}
          fontSize={["6xl", "4xl", "3xl"]}
        >
          {subtitle}
        </Box>
      )}
      {image && (
        <ImageWithOverlaycolor
          image={image && image.childImageSharp && image.childImageSharp.fluid}
          imageGray={
            !justInformation &&
            imageGray &&
            imageGray.childImageSharp &&
            imageGray.childImageSharp.fluid
          }
          aspectRatio={imageAspectRatio}
        />
      )}
      {image && !justInformation && showLightboxHint && (
        <Box
          pt={["0.6em", "0.6em", "0.6em"]}
          pb={["0.6em", "0.6em", "0.6em"]}
          fontFamily={useAlternativeFont ? "Overpass" : (fontFamily ? fontFamily : "initial")}
          fontStyle="italic"
        >
          Für die Bildergalerie bitte klicken
        </Box>
      )}
      {text && (
        <Box
          pt={["0.6em", "0.6em", "0.6em"]}
          letterSpacing="normal"
          color={textColor}
          lineHeight={["1.15em", "1.15em", "1.15em"]}
          fontSize={useAlternativeFont ? ["4xl", "2xl", "lg"] : ["4-5xl", "3xl", "xl"]}
          fontFamily={useAlternativeFont ? "Overpass" : (fontFamily ? fontFamily : "initial")}
          className={useAlternativeFont ? article_styles.altFont : ""}
        >
          <ReactMarkdown
            source={text}
            className={styles.event}
            plugins={[RemarkBreaks]}
          />
        </Box>
      )}
      <Box
        letterSpacing="normal"
        color={textColor}
        lineHeight={["1.15em", "1.15em", "1.15em"]}
        fontSize={["4-5xl", "3xl", "xl"]}
        fontFamily={fontFamily ? fontFamily : "initial"}
      >
        {copyright}
      </Box>
    </Flex>
  )
}

export default Article
