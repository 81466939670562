/*
  #18

  Universal Masory component.
  
  PROPS
    breakpointColumns: Obj. with keys for columns and values for "mediaquery"
      Ex. { 2: "(min-width: 0em)" }
    gap: String or Array added to Box margin-bottom. See Chakra-UI Box style
*/

import React, { useMemo } from "react"
import PropTypes from "prop-types"

import useMedia from "hooks/useMedia"
import { Flex, Box } from "@chakra-ui/core"

import MasonryColumn from "./masonryColumn"

const Masonry = ({
  breakpointColumns = {},
  defaultColumn = 2,
  gap = "1vw",
  children,
  // paddingTop = [
  //   ["6em", "6em", "3em"],
  //   ["0vw", "0vw", "0vw"],
  //   ["10em", "10em", "15em"],
  // ],
}) => {
  // const [columns, setColumns] = useState(1)
  const mediaQueryArr =
    !(Object.keys(breakpointColumns).length === 0) &&
    Object.values(breakpointColumns).map(key => key)

  const possibleColumnsArr =
    !(Object.keys(breakpointColumns).length === 0) &&
    Object.keys(breakpointColumns).map(key => key)

  // useEffect(() => {
  //   setColumns(useMedia(mediaQueryArr, possibleColumnsArr, defaultColumn))
  // }, [mediaQueryArr, possibleColumnsArr, defaultColumn])

  const columns = useMedia(mediaQueryArr, possibleColumnsArr, defaultColumn)

  const columnsObj =
    children &&
    children.length > 0 &&
    children.reduce((acc, child, index) => {
      const columnIndex = index % columns
      // articles & children
      const frame = (
        <Box key={index} mb={gap}>
          {child}
        </Box>
      )

      const stuff = !!acc[columnIndex] ? [...acc[columnIndex], frame] : [frame]

      const deep = { [columnIndex]: stuff }

      const result = { ...acc, ...deep }

      return result
    }, {})

  const column = useMemo(() => {
    if (!columnsObj) return null
    return (
      Object.values(columnsObj).map((column, index) => {
        // const paddingTop = [
        //   ["6em", "6em", "3em"],
        //   ["0vw", "0vw", "0vw"],
        //   ["10em", "10em", "15em"],
        // ]

        // columns
        return (
          <MasonryColumn
            key={`${index}${columns}`}
            column={column}
            columns={columns}
          />
        )
      }) || []
    )
  }, [columns, columnsObj])

  // const column =
  //   columnsObj &&
  //   Object.values(columnsObj).map((column, index) => {
  //     // const paddingTop = [
  //     //   ["6em", "6em", "3em"],
  //     //   ["0vw", "0vw", "0vw"],
  //     //   ["10em", "10em", "15em"],
  //     // ]

  //     // columns
  //     const result = (
  //       <Flex
  //         className="masonry-column"
  //         key={index}
  //         direction="column"
  //         w={`${100 / columns}%`}
  //         maxWidth={`${100 / columns}%`}
  //         // pt={paddingTop[index]}
  //         pt={0}
  //       >
  //         {column.map(item => item)}
  //       </Flex>
  //     )
  //     return result
  //   })

  return (
    // frame
    <Flex className="masonry" flex="0 1 auto">
      {column}
    </Flex>
  )
}

Masonry.propTypes = {
  // columns: PropTypes.number.isRequired,
  // gap: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
}

export default Masonry
