import React, { useState } from "react"

import { Box, Icon } from "@chakra-ui/core"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Masonry from "containers/masonry"
import Layout from "containers/layout"
import SEO from "components/seo"
import Article from "containers/article"
import Lightbox from "containers/lightbox"

const onlyClient = typeof window !== "undefined"

export const BuchhandlungTempl = ({ location, data, en: EN }) => {
  const [viewLightbox, setViewLightbox] = useState(false)
  const [lightboxData, setLightboxData] = useState({})
  const { t } = useTranslation()

  return (
    <Layout path={location.pathname}>
      <SEO
        title={
          data.strapi?.buchhandlung?.meta?.title ?? t("Buchhandlung")
        }
        description={data.strapi?.buchhandlung?.meta?.description}
      />
      <Box
        pt={["16em", "15.5em", "13em"]}
        pr={["4em", "10em", "5em"]}
        // h={["100%", "-webkit-fill-available", "calc(100vh - 5.6em)"]}
        minHeight={["100vh", "100vh", "calc(100vh - 5.6em)"]}
      >
        <Icon
          name="backgroundHouse"
          w={["200%", "120%", "70%"]}
          h={["auto", "auto", "auto"]}
          color="red"
          pos="fixed"
          zIndex="-1"
          bottom="0"
          left={["0%", "15%", "20%"]}
          opacity="0.3"
        />
        {onlyClient && (
          <Masonry
            breakpointColumns={{ 2: "(min-width: 75em)" }}
            gap={["5em", "5em", "4em"]}
            defaultColumn={1}
          >
            {data.strapi?.buchhandlung[EN ? "inhalt_en" : "inhalt"].map(
              (item, index) => {
                const listItem = item.article ? (
                  <Article
                    key={index}
                    title={item.article[EN ? "title_en" : "title"]}
                    text={item.article[EN ? "text_en" : "text"]}
                    useAlternativeFont={item.article.use_alternative_font}
                    showLightboxHint = "true"
                    subtitle={item.article[EN ? "subtitle_en" : "subtitle"]}
                    image={item.article.cover && item.article.cover.imageFile}
                    imageGray={
                      item.article.cover && item.article.cover.imageFileGray
                    }
                    gallery={item.article.gallery}
                    setData={setLightboxData}
                    viewLightbox={setViewLightbox}
                    justInformation={item.article.gallery.length === 0}
                    alt={
                      item.article.cover && item.article.cover.alternativeText
                    }
                    copyright={item.article.cover && item.article.cover.caption}
                  />
                ) : (
                  (item.title || item.text || item.image) && (
                    <Article
                      key={`${index}two`}
                      title={item.title}
                      text={item.text}
                      useAlternativeFont={item.use_alternative_font}
                      showLightboxHint = "true"
                      textColor="green"
                      image={item.image && item.image.imageFile}
                      justInformation={
                        item.__typename &&
                        (item.__typename.includes("Information") ||
                          item.__typename.toLowerCase().includes("text"))
                      }
                      alt={item.cover && item.cover.alternativeText}
                      copyright={item.cover && item.cover.caption}
                    />
                  )
                )

                return listItem
              }
            )}
          </Masonry>
        )}
        {(lightboxData.gallery) && (
          <Lightbox
            text={lightboxData.text}
            title={lightboxData.title}
            images={lightboxData.gallery}
            visibleFunc={setViewLightbox}
            visible={viewLightbox}
            animate={true}
            viewImageInfo={viewLightbox}
            useAlternativeFont={lightboxData.useAlternativeFont}
          />
        )}
      </Box>
    </Layout>
  )
}

export default BuchhandlungTempl
